import { useEffect, useState, useCallback } from 'react';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux'
import { Plus, ChevronDown } from 'react-feather';
import Animate from '../../components/animate';
import Item from '../../components/item';
import { useNavigate } from 'react-router-dom';
import { updateHeader } from '../../slices/header-slice';


const Structures = ({ type }) => {
    const navigate = useNavigate();
    const structures = useSelector((state) => type === "slabs" ? state.app.slabs : state.app.bases);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState([])
    const [activeFilter, setActiveFilter] = useState(null)
    const [filterResults, setFilterResults] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)

    useEffect(() => {
        dispatch(updateHeader('light'));

        if (structures) {
            const categories = structures.map(x => x.filter);
            const unique = categories.filter((c, index) => {
                return categories.indexOf(c) === index;
            });

            setFilters(unique)
        }
    }, [dispatch, structures]);

    useEffect(() => {
        if (activeFilter) {
            const result = structures.filter(x => x.filter === activeFilter);
            setFilterResults(result)
        }
    }, [activeFilter, structures]);

    const clearFilter = useCallback(() => {
        setActiveFilter(null);
        setFilterResults([])
    }, [])

    const onProductClick = (item) => {
        navigate('/product', { state: { item } })
    }

    return (
        <div className="store-page">

            {type === "slabs" ?

                <div className="store-intro">
                    <h1>Live Edge Slabs.</h1>
                    <p>We offer a range of Live Edge Slabs listed below that can be purchased separately for your project needs. Rare huge Single Slabs have the most limited availability due to the size of the tree from which they are harvested. We also have Premium Book Matches paired with hand picked sequential boards from the same tree offering extraordinary figuring and beautiful symmetry. Lastly we have Divergence Series Slabs that are custom sized to your exact dimensions and created from several rare hardwood planks and are our most cost effective. </p>
                </div>
                :
                <div className="store-intro">
                    <h1>Base Designs.</h1>
                    <p>We provide a variety of pre-made Base Designs listed below, available for separate purchase to suit your project requirements. Additionally, we offer custom base designs if none of the bases listed below align with your desired aesthetic or needs. Click on the images to view additional details.</p>
                </div>
            }
            <div className='tools-wrap'>
                <div className='tools'>
                    <div className='filter' onClick={() => setFilterOpen(!filterOpen)}>
                        {filterOpen
                            ? <span>Close</span>
                            : <span>Filters <Plus /></span>
                        }
                    </div>
                    <div className='sort'></div>
                </div>
                {filterOpen &&
                    <div className={`filter-content ${filterOpen ? "open" : ""}`}>
                        <div className='filter-options'>
                            {filters.map((filter, i) => {
                                return <div key={i}>
                                    <label className='custom-radio'>
                                        <input type='radio' name="filter" checked={filter === activeFilter} onChange={() => setActiveFilter(filter)} value={filter} />
                                        <span className="checkmark"></span>
                                        {filter}
                                    </label>
                                </div>
                            })}
                        </div>
                        <div className='filter-buttons'>
                            <button className='cta dark' onClick={() => clearFilter()}>Clear</button>
                        </div>
                    </div>
                }
            </div>

            <Animate>
                <div className="store-content items">
                    {!filterResults.length && structures && structures?.map((item, i) => (
                        <Item key={i} item={item} index={i} type={type} setItem={onProductClick} />
                    ))}

                    {filterResults && filterResults?.map((item, i) => (
                        <Item key={i} item={item} index={i} type={type} setItem={onProductClick} />
                    ))}
                </div>
            </Animate>
        </div>
    )
}

export default Structures;