import axios from 'axios'

const authHeader = "cGFyYW1vdW50Om45IXU3QGpoI3lwRjZnY3F3enhi";
const merchantid = "266443309882"

export const getApi = async () => {
    return await axios.get(`${process.env.REACT_APP_API}/api`)
}

export const register = async (email, password) => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/users/register`,
        data: {
            email: email,
            password: password
        }
    })
}

export const login = async (email, password) => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/users/login`,
        data: {
            email: "peter@test.com",
            password: "1234"
        }
    })
}

export const tokenizeCard = async (cardNumber, expirationDate, cvv) => {
    return await axios.post(
        `https://fts.cardconnect.com/cardconnect/rest/tokenize`,
        {
            account: "4444333322221111",
            expiry: "1225",
            cvv2: "123",
        },
        {
            headers: {
                Authorization: `Basic ${authHeader}`,
            },
        })
}


export const authorizePayment = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_WEBSITE_API}/api/payment/process-payment`,
        data
    )
}

export const contact = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_WEBSITE_API}/api/website/contact`,
        data
    )
}

export const customSubmission = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_WEBSITE_API}/api/website/custom`,
        data
    )
}