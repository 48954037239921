import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "framer-motion";
import { updateHeader } from "../../slices/header-slice";
import { Navigation, Pagination, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";

import Animate from '../../components/animate';

import story1 from '../../assets/images/story/story1.jpg';
import story2 from '../../assets/images/story/story2.jpg';
import story3 from '../../assets/images/story/story3.jpg';
import story4 from '../../assets/images/story/story4.jpg';

import { renderRating } from "../../utility/utils";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import './styles.scss';

const OurStory = () => {
    const dispatch = useDispatch();
    const testimonials = useSelector((state) => state.app.testimonials);
    const employees = useSelector((state) => state.app.employees);

    const ref = useRef(null);
    const isInView = useInView(ref, { once: false, amount: 0.1 });

    useEffect(() => {
        if (isInView) {
            dispatch(updateHeader('transparent'))

        } else {
            dispatch(updateHeader('dark'))
        }
    }, [isInView, dispatch]);

    useEffect(() => {
        dispatch(updateHeader('transparent'))
    }, [dispatch]);

    return (
        <div className="our-story">
            <div ref={ref} className="page-hero">
                <div className="hero-content">
                    <h1>Luxury Bespoke Designs</h1>
                    <p>Made in the USA.</p>
                </div>
            </div>

            <Animate>
                <div className="text-section dark">
                    <h2>We create unique and beautifully-designed luxury furniture from our studio in Bucks County, PA.</h2>
                    <p>Through dedicated craftsmanship, we use only the rarest and finest materials in the world; building creations that last a lifetime. We have a deep respect for the wood we use, and each piece is built on a unique and lasting story, but never one of deforestation.</p>
                </div>
            </Animate>

            <Animate>
                <div className="page-hero double">
                    <img alt="" src={story1} />
                    <img alt="" src={story2} />
                </div>
            </Animate>

            <Animate>
                <div className="video-section content">
                    <div className="text-section dark">
                        <h2>A Global Boutique</h2>
                        <p>Paramount Wood Co was founded by Colin Thompson, who brings with him a wealth of experience in fine craft wood making. His vision was of a practice principled on the ideals of sustainable processes with a keen focus on quality over quantity. He saw an opportunity to create a business where we actively celebrate the story of where our pieces come from. </p>
                        <Link className="cta">WATCH VIDEO</Link>
                    </div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/FqjUSBkKyQE?si=iYNFEIKgHgOHYIHG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </Animate>

            <Animate>
                <div className="testimonials dark">
                    <p>What our customers say</p>
                    {testimonials &&
                        <Swiper
                            modules={[Navigation, Pagination, EffectFade]}
                            navigation={true}
                            effect='fade'
                            fadeEffect={{
                                crossFade: true
                            }}
                            speed={1000}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: true,
                            }}
                        >
                            {testimonials?.map((testimonial, i) => (
                                <SwiperSlide key={i}>
                                    <div className='swiper-testimonial'>
                                        <h2>"{testimonial?.message}"</h2>
                                        <div className='stars'>{renderRating(testimonial.rating)}</div>
                                        <p className="author">{testimonial.author}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </div>
            </Animate>

            <Animate>
                <div className="page-hero double">
                    <img alt="" src={story3} />
                    <img alt="" src={story4} />
                </div>
            </Animate>

            <Animate>
                <div className="text-section dark">
                    <h2>We at Paramount Wood Co. believe in fine woodworking at the highest level of ethics & sustainability.</h2>
                    <p>We will never chopdown a tree to create your unique piece, we work with reclaimed material and trees that have fallen down through natural causes. The story of where our wood comes from adds character to our pieces. In fact, we plant a young tree for every piece we sell during our annual planting day in Bucks County, PA, USA.</p>
                </div>
            </Animate>

            <div className="team">
                <h2>The team behind our craft</h2>

                {employees &&
                    <div className="content">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={4}
                            modules={[Navigation]}
                            navigation={true}
                            effect=''
                        >
                            {employees.map((employee, i) => {
                                return <SwiperSlide key={i}>
                                    <div className="team-member">
                                        <div className="team-member-img">
                                            <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/images/employees/${employee.images[0].fileName}`} />

                                            <p>{employee.name}</p>
                                        </div>
                                        <div className="team-member-desc">
                                            <span>{employee.position}&nbsp;</span>
                                            {employee.description}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </div>
                }
            </div>

            <Animate>
                <div className="page-hero story">
                    <div className="hero-content">
                        <h1>We make Art not Furniture</h1>
                        <Link to="/" className="cta gold-fill">Get in touch</Link>
                    </div>
                </div>
            </Animate>
        </div>
    )
}

export default OurStory