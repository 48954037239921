import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import './styles.scss'
import { useNavigate } from "react-router-dom";
import { CheckCircle } from "react-feather";
import { showPreview } from "../../slices/app-slice";
import { formatPrice } from "../../utility/utils";

const CartPreview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const showOverlay = useSelector(state => state.app.showPreview)
    const quickAdd = useSelector(state => state.app.quickAddItem)

    const closeOverlay = () => {
        dispatch(showPreview(false))
    }

    const handleNav = (route) => {
        dispatch(showPreview(false))
        navigate(route)
    }

    return (
        <>
            {showOverlay &&
                <>
                    <div onClick={() => closeOverlay()} className="cart-preview-overlay"></div>
                    {quickAdd && quickAdd.product && 
                        <motion.div
                            layout
                            initial={{ y: 100, opacity: 0, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            animate={{ y: 0, opacity: 1, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            exit={{ y: 100, opacity: 0, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            transition={{ duration: 0.2 }}
                        >
                            <div className="cart-preview-content">
                                <div className="cart-preview-message">
                                    <CheckCircle />
                                    <p>Added to Cart</p>

                                </div>
                                <div className="cart-preview-item">
                                    <img alt='' width='135' src={`${process.env.REACT_APP_WEBSITE_API}/images/products/${quickAdd?.product?.images[0].fileName}`} />

                                    <div>
                                        <p>{quickAdd?.product?.title}</p>
                                        <p>{formatPrice(quickAdd?.total)}</p>
                                    </div>

                                </div>
                                <div className="cart-preview-actions">
                                    <button className="cta gold-fill" onClick={() => handleNav("/cart")}>View Cart</button>
                                    <button className="cta dark" onClick={() => handleNav("/check-out")}>Checkout</button>
                                </div>
                            </div>
                        </motion.div>

                    }
                </>
            }
        </>
    )
}

export default CartPreview;