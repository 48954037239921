import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateHeader } from './slices/header-slice';
import { Routes, Route } from 'react-router-dom';
import { updateData } from './slices/app-slice'

import './App.scss'

//Components
import Header from './components/header'
import Footer from './components/footer'
import Splash from './components/splash';
import Showcase from './components/showcase';
import CartPreview from './components/cart-preview';

//Pages
import Product from './pages/product';
import OurStory from './pages/story/our-story';
import JeffreyGreene from './pages/jeffrey-greene';
import Custom from './pages/custom/custom';
import CheckOut from './pages/checkout';
import TableBuilder from './pages/table-builder';
import Cart from './pages/cart';
import Store from './pages/store';
import Home from './pages/home/home';
import Portfolio from './pages/portfolio/portfolio';
import FAQs from './pages/faqs';
import Structures from './pages/structures';
import Contact from './pages/contact/contact';
import AdditionalServices from './pages/additional-services';

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [showCaseItem, setShowcaseItem] = useState(null);
  const [data, setData] = useState(null);

  let url = "/data/data.json";
  url = `${process.env.REACT_APP_WEBSITE_API}/api/website`

  useEffect(() => {
    const p = fetch(url).then((data) => data.json());
    p.then(res => {
      dispatch(updateData(res));
      setData(res)
    })
  }, [dispatch, url]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowcaseItem(null)
  }, [pathname]);

  useEffect(() => {
    if (showCaseItem) {
      dispatch(updateHeader('dark'))
      document.body.classList.add('modal');
      const showcaseEl = document.querySelector('.portfolio-showcase');
      showcaseEl.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      dispatch(updateHeader('light'))
      document.body.classList.remove('modal')
    }
  }, [showCaseItem, dispatch])

  return (
    <div>
      {data &&
        <>
          <Splash />
          <Header />
          <Routes>
            <Route path="/" element={<Home carousel={data.carousel} />} />
            <Route path="/portfolio" element={<Portfolio setShowcaseItem={setShowcaseItem} />} />
            <Route path="/shop" element={<Store />} />
            <Route path="/slabs" element={<Structures type={"slabs"} />} />
            <Route path="/bases" element={<Structures type={"bases"}  />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path='/jeffrey-greene' element={<JeffreyGreene />} />
            <Route path="/product" element={<Product />} />
            <Route path="/custom-design" element={<Custom />} />
            <Route path="/check-out" element={<CheckOut />} />
            <Route path="/table-builder" element={<TableBuilder />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/additional-services" element={<AdditionalServices />} />
          </Routes>

          <Showcase showCaseItem={showCaseItem} setShowcaseItem={setShowcaseItem} />

          <CartPreview />
          <Footer />
        </>
      }
    </div>
  );
}

export default App;
