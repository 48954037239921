

import { Link } from 'react-router-dom';

import logo from '../../assets/images/footer/footer-logo.svg';
import instagram from '../../assets/images/footer/instagram.svg';
import facebook from '../../assets/images/footer/facebook.svg';
import youtube from '../../assets/images/footer/youtube.svg';
import pinterest from '../../assets/images/footer/pinterest.svg';

import './styles.scss';

const Footer = () => {

    return (
        <div className='footer'>
            <div className='main'>
                <div className='content'>
                    <div className='col'>
                        <img className='logo' alt='' src={logo} />
                    </div>
                    <div className='col'>
                        <ul>
                            <li className='heading'>Shop</li>
                            <li><Link to="/table-builder">Table Builder</Link></li>
                            <li><Link to="/shop">Shop</Link></li>
                            <li><Link to="/custom-design">Custom Design</Link></li>
                        </ul>

                        <ul>
                            <li className='heading'>Studio</li>
                            <li><Link to="/portfolio">Portfolio</Link></li>
                            <li><Link to="/jeffrey-greene">Jeffrey Greene</Link></li>
                            <li><Link to="/our-story">Our Story</Link></li>
                            <li><Link to="/faqs">FAQs</Link></li>
                            <li><Link to="/">Contact</Link></li>
                        </ul>
                    </div>
                    <div className='col'>
                        <ul>
                            <li className='heading'>Studio Address</li>
                            <li>6162 Lower York Road</li>
                            <li>New Hope, PA 18938</li>
                        </ul>

                        <ul>
                            <li className='heading'>Contact</li>
                            <li>E: Hello@paramountwoodco.com</li>
                            <li>T: (412) 587-4166</li>
                        </ul>

                        <ul>
                            <li className='heading'>Studio Hours</li>
                            <li>Mon: 10 am to 6 pm</li>
                            <li>Tue & Wed: 10 am - 4 pm</li>
                            <li>Thu & Fri: 10 am - 6 pm</li>
                            <li>Sat: 10 am - 4 pm</li>
                            <li>Sun: Closed</li>
                        </ul>
                    </div>
                    <div className='col'>
                        <div className='subscribe'>
                            <h2>Subscribe to our Newsletter</h2>
                            <p>Get the latest news from our studio.</p>
                            <input type='email' placeholder='Email' />
                        </div>

                    </div>
                </div>
            </div>

            <div className='sub'>
                <div className='content'>
                    <div className='socials'>
                        <a href='https://www.instagram.com/paramountwoodco/' target='_blank'><img src={instagram} alt='Paramount Wood Co. Instagram' /></a>
                        <a href='https://www.facebook.com/ParamountWoodCo/' target='_blank'><img src={facebook} alt='Paramount Wood Co. Facebook' /></a>
                        <a href='https://www.youtube.com/@paramountwoodco7355/featured' target='_blank'><img src={youtube} alt='Paramount Wood Co. Youtube' /></a>
                        <a href='https://pinterest.com/paramountwoodcoshop/' target='_blank'><img src={pinterest} alt='Paramount Wood Co. Pinterest' /></a>
                    </div>
                    <div className='copyright'>Copyright ©2023, Paramount Wood Co.</div>
                </div>
            </div>
        </div>
    )
}

export default Footer;