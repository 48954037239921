import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { updateHeader } from "../../slices/header-slice";
import { useLocation } from "react-router-dom";
import { contact } from "../../api";
import Animate from '../../components/animate';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import './styles.scss';
import FeedbackModal from "../../components/feedback-modal";

const Contact = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const product = location?.state?.product;

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        interest: product ? product.title : "",
        details: ""
    });

    useEffect(() => {
        dispatch(updateHeader('dark'))
    }, [dispatch]);

    const submitContact = (e) => {
        e.preventDefault();
        setIsloading(true);
        contact(state).then((res) => {
            if (res.status === 200 || res.status === 204) {
                setIsSuccess(true);
            } else {
                setIsError(true);
            }

            setIsloading(false);
        }).catch((error) => {
            setIsError(true);
            setIsloading(false);
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const onFeedbackClose = () => {
        setIsSuccess(false)
        setIsError(false)
    }

    return (
        <>
            <div className="contact">

                <Animate>
                    <div className="text-section dark">
                        <h1>Contact us</h1>
                        <p>We hope you enjoyed viewing our work as much as we did making it. If you have a specific request or project idea, please don't hesitate to contact us directly through our contact form below.</p>
                    </div>
                </Animate>

                <Animate>
                    <div className="contact-form">
                        <form>
                            <div className="contact-inputs">
                                <div>
                                    <input type='text' name="firstName" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='First Name' />
                                    <input type='text' name="lastName" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Last Name' />
                                    <input type='email' name="email" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Email' />
                                </div>

                                <div>
                                    <input type='text' name="interest" defaultValue={state.interest} onChange={(e) => handleInputChange(e)} placeholder="I'm interested in" />
                                    <textarea defaultValue="" name="details" onChange={(e) => handleInputChange(e)} placeholder="Details"></textarea>
                                </div>
                            </div>

                            <button className={`cta gold-fill ${isLoading ? "loading" : ""}`} onClick={(e) => submitContact(e)}>
                                <div className="loader"></div>  
                                <span>Submit</span>
                            </button>
                        </form>
                    </div>
                </Animate>

            </div>

            {isSuccess &&
                <FeedbackModal status={"success"} type={"contact"} title={"Email Sent"} message={"Thank you for your enquiry, you will be contacted soon by one of the Paramount Wood Co. team"} onClose={onFeedbackClose} label={"Close"} />
            }

            {isError &&
                <FeedbackModal status={"error"} type={"contact"} title={"Email failed to send"} message={"Sorry, something went wrong while trying to send your email. Please try again."} onClose={onFeedbackClose} label={"Close"}/>
            }
        </>

    )
}

export default Contact