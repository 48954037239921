import { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux'
import { Plus, ChevronDown } from 'react-feather';

import Animate from '../../components/animate';
import Item from '../../components/item';
import { useNavigate } from 'react-router-dom';
import { updateHeader } from '../../slices/header-slice';


const Store = () => {
    const navigate = useNavigate();
    const products = useSelector((state) => state.app.products);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState([])
    const [activeFilter, setActiveFilter] = useState(null)
    const [filterResults, setFilterResults] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)

    useEffect(() => {
        dispatch(updateHeader('light'));

        if (products) {
            const categories = products.map(x => x.category);
            const unique = categories.filter((c, index) => {
                return categories.indexOf(c) === index;
            });

            setFilters(unique)
        }
    }, [dispatch, products]);

    useEffect(() => {
        if (activeFilter) {
            const result =  products.filter(x => x.category === activeFilter);
            setFilterResults(result)
        }
    }, [activeFilter, products]);

    const onProductClick = (item) => {
        navigate('/product', { state: { item } })
    }

    const clearFilter = useCallback(() => {
        setActiveFilter(null);
        setFilterResults([])
    }, [])

    return (
        <div className="store-page">

            <div className="store-intro">

                <h1>From our studio, to your home.</h1>
                <p>Welcome to our exclusive range of Paramount pieces available to purchase today. An opportunity to purchase your very own piece of the Paramount portfolio, our collection ranges from small tables, home accessories and merchandise. All products and works are made right here in Bucks County PA. </p>
            </div>

            <div className='tools-wrap'>
                <div className='tools'>
                    <div className='filter' onClick={() => setFilterOpen(!filterOpen)}>
                        { filterOpen 
                            ? <span>Close</span>
                            : <span>Filters <Plus /></span>
                        }
                    </div>
                    <div className='sort'></div>
                </div>
                {filterOpen &&
                    <div className={`filter-content ${filterOpen ? "open" : ""}`}>
                        <div className='filter-options'>
                            {filters.map((filter, i) => {
                                return <div key={i}>
                                    <label className='custom-radio'>
                                        <input type='radio' name="filter" checked={filter === activeFilter} onChange={() => setActiveFilter(filter)} value={filter} />
                                        <span class="checkmark"></span>
                                        {filter}
                                    </label>
                                </div>
                            })}
                        </div>
                        <div className='filter-buttons'>
                            <button className='cta dark' onClick={() => clearFilter()}>Clear</button>
                        </div>
                    </div>
                }
            </div>

            <Animate>
                <div className="store-content items">
                    {!filterResults.length && products && products?.map((item, i) => (
                        <Item key={i} item={item} index={i} type='product' setItem={onProductClick} />
                    ))}

                    {filterResults && filterResults?.map((item, i) => (
                        <Item key={i} item={item} index={i} type='product' setItem={onProductClick} />
                    ))}
                </div>
            </Animate>
        </div>
    )
}

export default Store;