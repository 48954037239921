import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { updateHeader } from "../../slices/header-slice";
import { useInView } from "framer-motion";

import Animate from '../../components/animate';

import story1 from '../../assets/images/jeffrey/1.jpg';
import story2 from '../../assets/images/jeffrey/2.jpg';
import story3 from '../../assets/images/jeffrey/3.jpg';
import story4 from '../../assets/images/jeffrey/4.jpg';

import './styles.scss';

const JeffreyGreene = () => {
    const dispatch = useDispatch();

    const ref = useRef(null);
    const isInView = useInView(ref, { once: false, amount: 0.1 });

    useEffect(() => {
        if (isInView) {
            dispatch(updateHeader('transparent'))

        } else {
            dispatch(updateHeader('dark'))
        }
    }, [isInView, dispatch]);

    useEffect(() => {
        dispatch(updateHeader('transparent'))
    }, [dispatch]);

    return (
        <div className="jeffrey-greene">
            <div ref={ref} className="page-hero">
                <div className="hero-content">
                    <h1>Jeffrey Greene Design</h1>
                    <p>A note from our founder.</p>
                </div>
            </div>

            <Animate>
                <div className="text-section dark">
                    <h2>As of Spring 2023, Paramount Wood Co. has completed the acquisition of Jeffrey Greene Design Studio.</h2>
                    <p>Jeffrey Greene has been professionally designing furniture for over fifty years and has built an exceptional reputation as a leader in furniture design and woodworking. The Jeffrey Greene name, brand and design process have joined the PWCO family, with Jeff himself coming on board as a key partner and design consultant moving forward. At the core, our businesses have a shared philosophy and passion for approaching craft, design and ethical working objectives. We offer our community a ‘design your own’ experience, that is both customizable and unique to Paramount with access to a diverse range of wood slabs, distinct - curated base variations, and finishes to meet our customers needs and preferences.</p>
                </div>
            </Animate>

            <Animate>
                <div className="page-hero double">
                    <img alt="" src={story1} />
                    <img alt="" src={story2} />
                </div>
            </Animate>

            <Animate>
                <div className="text-section dark">
                    <h2>About Jeffrey Greene.</h2>
                    <p>"One of my mentors, Phillip Lloyd Powel, said to me early in my career 'if you always do your best, you will never be bored with your work'. I took it to heart, and it’s still exciting to this day. Building a dining table that will serve as a gathering place for families and friends for countless generations is a deep source of satisfaction for me."</p>
                    <p>Jeffrey Greene has been professionally designing furniture for over fifty years. While growing up he lived with his painter/sculptor father George Greene in the art colony of Bucks County, PA. His father exposed him to the creative process as a way of life, as he encouraged the importance of developing his drawing skills. He returned to New Hope, PA, in the 70’s where he set up a woodworking studio as an alternative life style rather than pursuing a career in Psychology, for which he had gone to graduate school and obtained a Masters degree. Jeffrey Greene is largely self-taught in the art and technique of furniture design and construction, although he credits furniture maker Nakashima, wood sculptor James Martin, and designer/artisan Phillip Lloyd Powell as formative influences.</p>
                    <p>Jeffrey Greene’s early work was inspired by George Nakashima and taken in his own direction. His current designs have come full circle returning to the live edge slab approach of construction. The many years of intervening design experience have come into play in structural innovations and subtle proportional accents in his new pieces. The introduction of custom designed metal elements of cast bronze and aluminum, as well as welded steel, are examples of these innovations.</p>

                </div>
            </Animate>

            <Animate>
                <div className="page-hero double">
                    <img alt="" src={story3} />
                    <img alt="" src={story4} />
                </div>
            </Animate>    
        </div>
    )
}

export default JeffreyGreene