import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Info } from "react-feather";

import './styles.scss'
import { updateHeader } from "../../slices/header-slice";
import { formatCreditCardNumber, formatExpirationDate, formatCVC, formatPrice } from "../../utility/utils";
import { authorizePayment } from "../../api";
import FeedbackModal from "../../components/feedback-modal";

const CheckOut = () => {
  const cartItems = useSelector(state => state.app.cart);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [showReadMore, setShowReadMore] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    address: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
  });

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      setIsLoading(true);

      setState((prevState) => {
        const data = {
          merchid: "",
          country: prevState.country,
          deliveryName: `${prevState.firstname} ${prevState.lastname}`,
          address: prevState.address,
          city: prevState.city,
          region: prevState.state,
          postal: prevState.zip,
          phone: prevState.phone,
          email: prevState.email,
          account: prevState.number,
          expiry: prevState.expiry,
          cvv2: prevState.cvc,
          name: prevState.name,
          amount: `${total}`,
          currency: "USD",
          products: cartItems.map((item) => ({
            id: item.product.id,
            title: item.product.title,
            price: item.product.price,
            quantity: item.quantity,
            total: item.total,
          })),
        };

        authorizePayment(data).then((res) => {
          const responseData = res?.data;
          console.log(responseData)
          if (responseData && responseData?.respcode === "200") {
            if (responseData.respstat === "A") {
              setIsSuccess(true)
            } else if (responseData.respstat === "B") {
              setIsError(true)
            } else {
              setIsDeclined(true)
            }
          } else {
            setIsError(true);
          }

          setIsLoading(false);
        }).catch((error) => {
          setIsError(true);
          setIsLoading(false);
        });

        return prevState;
      });
    }
  }, [errors, total, submitting, cartItems]);

  const validateValues = (inputValues) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let errors = {};

    if (inputValues.email.length === 0 || !emailRegex.test(inputValues.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (inputValues.country.length === 0) {
      errors.country = "Please select a country.";
    }
    if (inputValues.firstname.length === 0) {
      errors.firstname = "Please provide a first name.";
    }
    if (inputValues.lastname.length === 0) {
      errors.lastname = "Please provide a last name.";
    }
    if (inputValues.address.length < 1) {
      errors.address = "Please provide an address.";
    }
    if (inputValues.city.length === 0) {
      errors.city = "Please provide a city.";
    }
    if (inputValues.state.length === 0) {
      errors.state = "Please provide a state or region.";
    }
    if (inputValues.zip.length === 0) {
      errors.zip = "Please provide a postal or zip code.";
    }
    if (inputValues.number.length < 16) {
      errors.number = "Please provide a valid credit card number.";
    }
    if (inputValues.cvc.length < 3) {
      errors.cvc = "Please provide a valid CVC number.";
    }
    if (inputValues.expiry.length < 4) {
      errors.expiry = "Please provide a valid expiry date.";
    }
    if (inputValues.name.length === 0) {
      errors.name = "Please provide a valid cardholder name.";
    }

    return errors;
  };

  const checkout = (e) => {
    e.preventDefault();
    setErrors(validateValues(state));
    setSubmitting(true);
  }

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    let formatted = "";

    if (e.target.name === "expiry") {
      formatted = formatExpirationDate(value)
    }

    if (e.target.name === "number") {
      formatted = formatCreditCardNumber(value)
    }

    if (e.target.name === "cvc") {
      formatted = formatCVC(value)
    }

    setState((prev) => ({ ...prev, [name]: formatted ? formatted : value }));
  };

  const handleInputFocus = (e) => {
    setState((prev) => ({ ...prev, focus: e.target.name }));
  };

  useEffect(() => {
    let t = 0;
    cartItems.forEach(element => {
      t += element.total;
    })
    setTotal(parseFloat((t).toFixed(2)))

  }, [cartItems])

  useEffect(() => {
    dispatch(updateHeader('light'))
  }, [dispatch]);

  const onFeedbackClose = () => {
    setIsSuccess(false)
    setIsError(false)
    setIsDeclined(false)
    setShowReadMore(false)
  }

  return (
    <>
      <div className="checkout-page">
        <div className="checkout-page-content">
          <h1>Checkout</h1>
          <div>
            <div className="checkout-page-form">
              <form>
                <div className="checkout-section">
                  <h2>Contact</h2>
                  <input type="email" defaultValue="" name="email" onChange={(e) => handleInputChange(e)} placeholder="Email" />
                  {errors["email"] &&
                    <span>{errors["email"]}</span>
                  }
                </div>

                <div className="checkout-section">
                  <h2>Delivery</h2>

                  <div>
                    <div>
                      <select name="country" className="form-control" id="country" onChange={(e) => handleInputChange(e)}>
                        <option defaultValue="0" label="Select a country... " selected="selected">Select a country ... </option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Åland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia, Plurinational State of</option>
                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">British Indian Ocean Territory</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">Congo, the Democratic Republic of the</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Côte d'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curaçao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">Heard Island and McDonald Islands</option>
                        <option value="VA">Holy See (Vatican City State)</option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea, Democratic People's Republic of</option>
                        <option value="KR">Korea, Republic of</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Lao People's Democratic Republic</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia, Federated States of</option>
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">Palestinian Territory, Occupied</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Réunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten (Dutch part)</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM">United States Minor Outlying Islands</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela, Bolivarian Republic of</option>
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.S.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                      {errors["country"] &&
                        <span>{errors["country"]}</span>
                      }
                    </div>
                  </div>


                  <div>
                    <div>
                      <input type="text" name="firstname" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder="First Name" />
                      {errors["firstname"] &&
                        <span>{errors["firstname"]}</span>
                      }
                    </div>

                    <div>
                      <input type="text" name="lastname" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder="Last Name" />
                      {errors["lastname"] &&
                        <span>{errors["lastname"]}</span>
                      }
                    </div>
                  </div>

                  <div>
                    <div>
                      <input type="text" name="address" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder="Address" />
                      {errors["address"] &&
                        <span>{errors["address"]}</span>
                      }
                    </div>
                  </div>

                  <div>
                    <div>
                      <input type="text" name="city" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder="City" />
                      {errors["city"] &&
                        <span>{errors["city"]}</span>
                      }
                    </div>
                    <div>
                      <input type="text" name="state" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder="State" />
                      {errors["state"] &&
                        <span>{errors["state"]}</span>
                      }
                    </div>
                    <div>
                      <input type="text" name="zip" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder="Zip" />
                      {errors["zip"] &&
                        <span>{errors["zip"]}</span>
                      }
                    </div>
                  </div>

                  <div>
                    <input type="tel" name="phone" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder="Phone" />
                  </div>
                </div>

                <div className="checkout-section">
                  <h2>Shipping Method</h2>
                  {cartItems?.filter(x => x.product.customShipping).length === 0
                    ? <select onChange={handleInputChange}>
                      <option defaultValue="">UPS Standard</option>
                    </select>
                    : <div className='info'>
                      <Info /><p>One or more items require&nbsp;<span onClick={() => setShowReadMore(true)}>specialized shipping</span></p>
                    </div>
                  }
                </div>

                <div className="checkout-section">
                  <h2>Payment Details</h2>

                  <div>
                    <div>
                      <input
                        type="tel"
                        name="number"
                        className="form-control"
                        placeholder="Card Number"
                        value={state.number}
                        onChange={(e) => handleInputChange(e)}
                        onFocus={(e) => handleInputFocus(e)}
                        required
                      />
                      {errors["number"] &&
                        <span>{errors["number"]}</span>
                      }
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="tel"
                        name="expiry"
                        className="form-control"
                        placeholder="Expiration Date"
                        pattern="\d\d/\d\d"
                        value={state.expiry}
                        onChange={(e) => handleInputChange(e)}
                        onFocus={(e) => handleInputFocus(e)}
                        required
                      />
                      {errors["expiry"] &&
                        <span>{errors["expiry"]}</span>
                      }
                    </div>
                    <div>
                      <input
                        type="tel"
                        name="cvc"
                        className="form-control"
                        placeholder="CVC"
                        pattern="\d{3,4}"
                        required
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                      />
                      {errors["cvc"] &&
                        <span>{errors["cvc"]}</span>
                      }
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name on Card"
                        defaultValue={state.name}
                        onChange={(e) => handleInputChange(e)}
                        onFocus={(e) => handleInputFocus(e)}
                        required
                      />
                      {errors["name"] &&
                        <span>{errors["name"]}</span>
                      }
                    </div>
                  </div>              
                </div>
                <button className={`cta gold-fill ${isLoading ? "loading" : ""}`} onClick={(e) => checkout(e)}>
                    <div className="loader"></div>
                    <span>Checkout</span>
                  </button>
              </form>
            </div>
            <div className="checkout-page-summary">
              <div>
                <h2>In Your Cart</h2>
                <div>
                  <p>Subtotal</p>
                  <p>${total}</p>
                </div>
                <div>
                  {cartItems?.filter(x => x.product.customShipping).length === 0
                    ? <>
                      <p>Shipping</p>
                      <p>$</p></>
                    : <>
                      <p>Shipping</p>
                      <p>Specialized</p>
                    </>
                  }
                </div>

                <div className="total">
                  <p>Total</p>
                  <h2>{formatPrice(total)} USD</h2>
                </div>

                <div className="product-summary">
                  {cartItems?.map((cartItem, i) => {
                    return <div key={i}>
                      <div className="product-summary-img">
                        <img alt='' width='106' src={`${process.env.REACT_APP_WEBSITE_API}/images/products/${cartItem?.product?.images[0].fileName}`} />
                        <div className="indicator">{cartItem?.quantity}</div>
                      </div>
                      <div>
                        <p>{cartItem?.product?.title}</p>
                        <p>{formatPrice(cartItem?.total)}</p>
                      </div>
                    </div>
                  })}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {showReadMore &&
        <FeedbackModal status={""} type={"info"} title={"Specialized Shipping"} message={"Some items are too large and heavy for conventional shipping methods. After your purchase, we will contact you to arrange an alternative at a competitive price."} onClose={onFeedbackClose} label={"Close"} />
      }

      {isSuccess &&
        <FeedbackModal status={"success"} type={"checkout"} title={"Payment Successful"} message={"Thank you for your purchase. Feel free to continue browsing."} onClose={onFeedbackClose} label={"Continue"} />
      }

      {isError &&
        <FeedbackModal status={"error"} type={"checkout"} title={"Payment Error"} message={"Sorry, something went wrong while trying to process your payment. Please try again."} onClose={onFeedbackClose} label={"Close"} />
      }

      {isDeclined &&
        <FeedbackModal status={"error"} type={"checkout"} title={"Payment Declined"} message={"Sorry, your payment has been declined. Please try again and make sure all details are correct."} onClose={onFeedbackClose} label={"Close"} />
      }
    </>
  )
}

export default CheckOut;