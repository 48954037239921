import { useEffect, useRef, useCallback } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useScrollDirection } from '../../hooks/scrollDirection';

import Cart from '../cart';

import './styles.scss';

import logo from '../../assets/images/menu/logo.svg';
import logoWhite from '../../assets/images/menu/logo_white.svg';
import hamburger from '../../assets/images/menu/hamburger.svg';
import hamburgerWhite from '../../assets/images/menu/hamburger_white.svg';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [hideBav, setHideNav] = useState(false);

    const ref = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const scrollDirection = useScrollDirection()
    const location = useLocation();
    
    const headerState = useSelector((state) => state.header.background);
    const isShowcase = useSelector((state) => state.header.isShowcase);

    const closeDropdown = useCallback((e) => {
        if (ref.current && isOpen && !ref.current.contains(e.target)) {
            setIsOpen(false);
        }
    }, [isOpen]);


    useEffect(() => {
        switch (scrollDirection) {
            case 'down':
                setHideNav(true)
                break;
            case 'up':
                setHideNav(false);
                break;
            default:
                setHideNav(false);
                break;
        }
    }, [scrollDirection])

    useEffect(() => {
        if (window.PointerEvent) {
            document.addEventListener("pointerdown", closeDropdown);
        } else {
            document.addEventListener("pointerdown", closeDropdown);
        }
    }, [closeDropdown]);

    useEffect(() => {
        setIsOpen(false);
    }, [location.pathname, dispatch, headerState]);

    return (

        <div ref={ref} className={`header-wrapper`}>
            <div className={`header ${isOpen ? 'open' : 'closed'} ${headerState} ${hideBav && !isOpen && !isShowcase ? 'hide' : ''}`}>
                <div className='header-menu-btn' onClick={() => setIsOpen(!isOpen)}><img alt='' src={headerState === "light" || (isOpen && headerState === "transparent") ? hamburger : hamburgerWhite} />Menu</div>
                <Link to="/">
                    {headerState === "light" || (isOpen && headerState === "transparent") ? <div className='header-logo'><img alt='' src={logo} /></div> : <div className='header-logo'><img alt='' src={logoWhite} /></div>}
                </Link>
                <div className='header-tools'>
                    <Cart />
                </div>
            </div>

            <div className={`header-menu-wrapper ${isOpen ? 'open' : 'closed'}`}>
                <div className='header-menu'>
                    <ul>
                        <li><label>Shop</label></li>
                        <li><span><Link to="/shop">Shop</Link></span></li>
                        <li><span><Link to="/custom-design">Custom Design</Link></span></li>
                        <li><span className='tag'><Link to="/table-builder">Table Builder</Link></span></li>
                        <li><span><Link to="/slabs">Slabs</Link></span></li>
                        <li><span className='mb-2'><Link to="/bases">Bases</Link></span></li>                                  

                        <li><label>Studio</label></li>
                        <li><span><Link to="/portfolio">Portfolio</Link></span></li>
                        <li><span><Link to="/jeffrey-greene">Jeffrey Greene</Link></span></li>
                        <li><span><Link to="/our-story">Our Story</Link></span></li>
                        <li><span><Link to="/additional-services">Additional Services</Link></span></li>
                        <li><span><Link to="/contact">Contact</Link></span></li>
                    </ul>
                </div>

                <div className='header-promos'>
                    <div className='promo hover-zoom' onClick={() => navigate("/table-builder")}>
                        <div className='overlay'>
                            <h2>Table Builder</h2>
                            <p>Coming soon.</p>
                        </div>
                    </div>
                    <div className='promo hover-zoom' onClick={() => navigate("/shop")}>      
                        <div className='overlay'>
                            <h2>Shop</h2>
                            <p>A curated selection of artisan pieces.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;