import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import './styles.scss'
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const cartItems = useSelector(state => state.app.cart);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate()

  useEffect(() => {
    if (cartItems && cartItems.length) {
      let t = 0;
      cartItems?.forEach(element => {
        t += element.quantity;
      })
      setTotal(t)
    } else {
      setTotal(0)
    }

  }, [cartItems, cartItems.length])


  return <div className="header-cart">
    <p onClick={() => navigate("/cart")}>Cart ({total})</p>
  </div>
}

export default Cart;