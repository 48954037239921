import { useState, useEffect } from "react";

export const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = useState('top');

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.scrollY;
        let ticking = false;
    
        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
    
            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            
            setScrollDirection(scrollY === 0 ? "top" : (scrollY > lastScrollY ? 'down' : 'up'));
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        }
    
        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDirection);
                ticking = true;
            }
        }
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    return scrollDirection;
}