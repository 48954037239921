import { useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import { Minus, Plus, Info } from 'react-feather';
import { Swiper, SwiperSlide } from 'swiper/react';
import { setQuickAddItem, showPreview, updateCart } from '../../slices/app-slice';
import { updateHeader } from '../../slices/header-slice';

import 'swiper/css';
import 'swiper/css/navigation';

import Animate from '../../components/animate';
import { Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import Item from '../../components/item';
import { formatPrice } from '../../utility/utils';

import instagram from '../../assets/images/footer/instagram.svg';
import facebook from '../../assets/images/footer/facebook.svg';
import youtube from '../../assets/images/footer/youtube.svg';
import pinterest from '../../assets/images/footer/pinterest.svg';
import FeedbackModal from '../../components/feedback-modal';

const Product = () => {
    const [quantity, setQuantity] = useState(1);
    const [showReadMore, setShowReadMore] = useState(false);
    const [recommendedProducts, setRecommendedProducts] = useState([])

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const product = location.state.item;
    const products = useSelector((state) => state.app.products);
    const structures = useSelector((state) => product?.category === "slabs" ? state.app.slabs : state.app.bases);

    const isStructure = product?.category?.toLowerCase() === "bases" || product?.category?.toLowerCase() === "slabs";

    const handleQuantityClick = useCallback((val) => {
        if (val <= product.stock) {
            setQuantity(val < 0 ? 0 : val)
        } else {
            setQuantity(product.stock)
        }

    }, [product])

    useEffect(() => {
        let array = [];
        let parentProduct;

        if (!isStructure) {
            if (product.productId) {
                parentProduct = products.filter(p => p.id === product.productId)[0]
                array.push(parentProduct)
            }

            if (product.variants) {
                if (product.variants.length <= 4) {
                    product.variants.slice(0, product.variants.length).map((v) => array.push(v))
                    products.filter(x => (x.id !== product.id && x.title !== product.title)).slice(0, (4 - product.variants.length)).map((p) => array.push(p))
                } else {
                    if (product.variants.length > 4) {
                        product.variants.slice(0, 4).map((v) => array.push(v))
                    }
                }
            } else {
                if (parentProduct) {
                    products.filter(x => (x.id !== parentProduct.id && x.title !== parentProduct.title)).slice(0, 4).map((p) => array.push(p))
                } else {
                    products.filter(x => (x.id !== product.id && x.title !== product.title)).slice(0, 4).map((p) => array.push(p))
                }
            }
        } else {
            structures.filter(x => (x.id !== product.id && x.title !== product.title)).slice(0, 4).map((p) => array.push(p))
        }

        setRecommendedProducts(array)
    }, [products, product, structures, isStructure]);

    useEffect(() => {
        dispatch(updateHeader('light'))
    }, [dispatch]);

    const addToCart = useCallback(() => {
        dispatch(updateCart({
            product: product,
            quantity: quantity,
            total: product.price * quantity
        }))

        dispatch(setQuickAddItem({
            product: product,
            quantity: 1,
            total: product.price
        }))

        dispatch(showPreview(true))
    }, [dispatch, product, quantity])

    const inquire = useCallback(() => {
        navigate('/contact', { state: { product } });
    }, [navigate, product])

    const onProductClick = (item) => {
        navigate('/product', { state: { item } });
        window.scrollTo(0, 0);
    }

    const onFeedbackClose = () => {
        setShowReadMore(false)  
    }

    return (
        <>
            <div className="product-page">

                <Animate>
                    <div className='product'>
                        <div className="product-gallery">
                            {product?.images &&
                                <Swiper
                                    slidesPerView={1}
                                    modules={[Navigation]}
                                    navigation={true}
                                >
                                    {product?.images.map((img, i) => (
                                        <SwiperSlide key={i}>
                                            <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/images/${isStructure ? product?.category : "products"}/${img.fileName}`} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            }
                        </div>

                        <div className="product-info">


                            {!isStructure ?
                                <>
                                    <p className='product-category'>{product?.category}</p>
                                    <h1>{product?.title}</h1>
                                    <p className='product-price'>{formatPrice(product?.price)}</p>

                                    <div className='product-quantity'>
                                        <div className='circle-btn' onClick={() => handleQuantityClick(quantity - 1)}><Minus /></div>
                                        <div>{quantity}</div>
                                        <div className='circle-btn' onClick={() => handleQuantityClick(quantity + 1)}><Plus /></div>
                                    </div>

                                    <div className='product-actions'>
                                        <button className='cta gold-fill' onClick={() => addToCart()}>Add to cart</button>
                                        {/* <button className='cta dark-fill'>Afterpay</button> */}


                                    </div>
                                </>
                                :
                                <>
                                    <h1>{product?.title}</h1>
                                    <button className='cta gold-fill mt-4' onClick={() => inquire()}>Inquire about availability</button>
                                </>
                            }


                            <div className='socials'>
                                <a href='https://www.instagram.com/paramountwoodco/' target='_blank'><img src={instagram} alt='Paramount Wood Co. Instagram' /></a>
                                <a href='https://www.facebook.com/ParamountWoodCo/' target='_blank'><img src={facebook} alt='Paramount Wood Co. Facebook' /></a>
                                <a href='https://www.youtube.com/@paramountwoodco7355/featured' target='_blank'><img src={youtube} alt='Paramount Wood Co. Youtube' /></a>
                                <a href='https://pinterest.com/paramountwoodcoshop/' target='_blank'><img src={pinterest} alt='Paramount Wood Co. Pinterest' /></a>
                            </div>

                            <div className='product-description'>
                                {product.customShipping &&
                                    <div className='info'>
                                        <Info /><p>This item requires&nbsp;<span onClick={() => setShowReadMore(true)}>specialized shipping</span></p>
                                    </div>
                                }

                                {product?.description &&
                                    <>
                                        <h3>Description</h3>
                                        <div className='html-text' dangerouslySetInnerHTML={{ __html: product?.description }} />
                                    </>
                                }

                                {product?.specification &&
                                    <>
                                        <h3>Specification</h3>
                                        <div className='html-text' dangerouslySetInnerHTML={{ __html: product?.specification }} />
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </Animate>

                {recommendedProducts?.length > 0 &&
                    <div className='product-other'>
                        <h2>You may also like</h2>
                        <div className='items'>
                            {recommendedProducts.map((item, i) => (
                                <Item key={i} item={item} index={i} setItem={onProductClick} type={!isStructure ? 'product' : product?.category} />
                            ))}
                        </div>
                    </div>
                }
            </div>

            {showReadMore &&
                <FeedbackModal status={""} type={"info"} title={"Specialized Shipping"} message={"Some items are too large and heavy for conventional shipping methods. After your purchase, we will contact you to arrange an alternative at a competitive price."} onClose={onFeedbackClose} label={"Close"} />
            }
        </>
    )
}

export default Product;