
import { useCallback, useEffect, useState } from "react";
import './styles.scss';
import { MinusCircle, PlusCircle } from "react-feather";

const Stepper = ({ updateQuantity, cartItem }) => {
    const [value, setValue] = useState(cartItem.quantity);

    const updateValue = useCallback((type) => {
        if (type === "plus" && cartItem.product.stock <= value) {    
            setValue(cartItem.product.stock)
            return;
        } 
        if (type) {
            let x = value;

            if (type === "minus") {
                x--
            } else {
                x++
            }

            setValue(x >= 0 ? x : 0)
        }
    }, [value, cartItem.product.stock])

    useEffect(() => {
        if ((value || value === 0) && cartItem && cartItem.product) {
            updateQuantity(value, cartItem.product)
        }
    }, [value, updateQuantity, cartItem, cartItem.product])

    return (
        <div className="stepper-wrapper">
            <div className="stepper">
                <div onClick={() => updateValue("minus")}><MinusCircle /></div>
                <p>{value}</p>
                <div onClick={() => updateValue("plus")}><PlusCircle></PlusCircle></div>
            </div>
        </div>
    )
}

export default Stepper;