import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProductQuantity, removeCartItem } from "../../slices/app-slice";
import Stepper from "../../components/stepper";

import './styles.scss'
import { updateHeader } from "../../slices/header-slice";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../utility/utils";

const Cart = () => {
    const cartItems = useSelector(state => state.app.cart);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let t = 0;
        cartItems.forEach(element => {
            if (element.product.stock) {
                t += element.total;
            }
        })
        setTotal(parseFloat((t).toFixed(2)))

    }, [cartItems])

    useEffect(() => {
        dispatch(updateHeader('light'))
    }, [dispatch]);

    const updateQuantity = (value, product) => {
        if (value || value === 0) {
            dispatch(updateProductQuantity({ id: product.id, title: product.title, quantity: parseInt(value) }))
        }
    }

    const removeItem = (id) => {
        dispatch(removeCartItem(id))
    }

    return (
        <div className="cart-page">
            <div className="cart-page-content">
                <h1>Cart</h1>
                <div>
                    <div className="cart-page-items">

                        <ul>
                            <li className="titles">
                                <div>
                                    <h2>Product</h2>
                                </div>
                                <div></div>
                                <div>
                                    <h2>Quantity</h2>
                                </div>
                                <div>
                                    <h2>Total</h2>
                                </div>
                            </li>


                            {cartItems?.length === 0 &&
                                <p className="feedback">It looks like your cart is empty.</p>
                            }

                            {cartItems?.map((cartItem, i) => {
                                return <li key={i}>
                                    <div className="item-img">
                                        <img alt='' width='135' src={`${process.env.REACT_APP_WEBSITE_API}/images/products/${cartItem?.product?.images[0].fileName}`} />
                                        <p className="remove" onClick={() => removeItem(cartItem?.product?.id)}>X Remove</p>
                                    </div>
                                    <div className="item-desc">
                                        <p>{cartItem?.product?.title} {cartItem?.product?.stock ? `(${cartItem?.product?.stock} in stock)` : ""}</p>
                                    </div>

                                    {cartItem?.product?.stock ?
                                        <>
                                            <div className="item-quantity">
                                                <Stepper updateQuantity={updateQuantity} cartItem={cartItem} />
                                            </div>
                                            <div className="item-total">
                                                <p>{formatPrice(cartItem?.total)}</p>
                                            </div>
                                        </>
                                        :
                                        <><p>Unfortunately, this product is out of stock</p></>
                                    }

                                </li>

                            })}
                        </ul>
                    </div>
                    <div className="cart-page-summary">
                        <div>
                            <h2>Cart Total</h2>
                            {total > 0 &&
                                <h2 className="total">{formatPrice(total)} USD</h2>
                            }
                            <p>Tax included. Shipping calculated at checkout.</p>

                            <button disabled={cartItems?.length === 0} onClick={() => navigate("/check-out")} className="cta gold-fill">CHECKOUT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart;