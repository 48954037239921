
import './styles.scss';
import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/splash/splash.gif';

const Splash = () => {
    const [showSplash, setShowSplash] = useState(true)

    useEffect(() => {
      setTimeout(() => {
        setShowSplash(false);
      }, 1500)
    })

    return (
        <div className={`splash ${!showSplash ? "hide" : ''}`}>
           <img alt='' src={logo} />
        </div>
    )
}

export default Splash;