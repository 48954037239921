import { useEffect, useState } from 'react';
import './styles.scss';
import { formatPrice } from '../../utility/utils';
import { useDispatch } from 'react-redux';
import { setQuickAddItem, showPreview, updateCart } from '../../slices/app-slice';
import { motion } from "framer-motion";

const Item = ({ item, index, setItem, type = "standard" }) => {
    const [image, setImage] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (item?.images?.length) {
            setImage(item.images[0].fileName)
        }
    }, [item])

    const quickAdd = (event, item) => {
        event.stopPropagation();

        dispatch(updateCart({
            product: item,
            quantity: 1,
            total: item.price
        }))

        dispatch(setQuickAddItem({
            product: item,
            quantity: 1,
            total: item.price
        }))

        dispatch(showPreview(true))
    }


    return <>
        {type === "product" || type === "slabs" || type === "bases"
            ? <>
                {item && image &&
                    <div className='item-product-wrapper'>
                        <div onMouseLeave={() => setImage(item.images[0].fileName)} onMouseEnter={() => setImage(item.images.length > 1 ? item.images[1].fileName : item.images[0].fileName)} onClick={() => { setItem(item) }} className='item-product'>
                            <div className={`item-tag ${item?.tag && item?.tag !== "None" ? "has-tag" : ""}`}>
                                {item?.tag && item?.tag !== "None" &&
                                    <div className={`pill ${item.tag.toLowerCase() === "best seller" ? "gold" : "bark"}`}>{item?.tag}</div>
                                }

                                {type === "product" && <div className="pill quick-add" onClick={(e) => quickAdd(e, item)}>+ Quick add</div>}
                            </div>

                            {image &&

                                <motion.div
                                    key={image}
                                    variants={variants}
                                    animate={'show'}
                                    initial={'hide'}
                                    className='item-img-container'
                                >
                                    <div className='item-img'>
                                        <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/images/${type === "product" ? "products" : type}/${image}`} />
                                    </div>
                                </motion.div>

                            }
                            <div className={`item-details ${item.lightText ? "light" : ""}`}>
                                <p>{item?.title}</p>

                                {type !== "product" ? <></> : <p>{formatPrice(item?.price)}</p>}
                            </div>
                        </div>

                        <div className='variant-pickers'>
                            {item.variants && item.variants.map((variant, i) => {
                                return (
                                    <div key={i} onClick={() => { setItem(variant) }} title={variant.title} className='variant-picker'>{variant.thumbnail ? <img src={`${process.env.REACT_APP_WEBSITE_API}/images/thumbnails/${variant.thumbnail}`} alt='' /> : <></>}</div>
                                )
                            })}
                        </div>
                    </div>
                }
            </>
            : <div className='item hover-zoom' onClick={() => { setItem(item) }}>
                {image &&
                    <div className='item-bg' style={{ backgroundImage: `url("${`${process.env.REACT_APP_WEBSITE_API}/images/projects/${image}`}")` }}> </div>
                }
                <div className='item-overlay'>
                    {item.title ? <p>{item.title}</p> : <></>}
                    {item.type ? <p>{item.type}</p> : <></>}
                </div>

            </div>
        }

    </>
}

export default Item;

export const variants = {
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: 'easeIn',
            duration: 0.3
        }
    },
    hide: {
        y: 0,
        opacity: 0,
        transition: {
            ease: 'easeOut',
            duration: 0.3
        }
    }
};