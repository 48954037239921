import { createSlice } from '@reduxjs/toolkit'

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        background: "light",
        isShowcase: false
    },
    reducers: {
        updateHeader: (state, action) => {
            state.background = action.payload
        },
        updateIsShowcase: (state, action) => {
            state.isShowcase = action.payload
        },
    },
})

export const { updateHeader, updateIsShowcase } = headerSlice.actions

export default headerSlice.reducer