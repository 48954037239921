import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { updateHeader } from "../../slices/header-slice";
import Animate from '../../components/animate';

import './styles.scss';
import Accordion from "../../components/accordion";

const FAQs = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateHeader('light'))
    }, [dispatch]);

    return (
        <div className="faqs">
            <h1>FAQ</h1>

            <Animate>

                <div className="content">
                    <h2>Table Top Options</h2>
                    <Accordion data={
                        [
                            {
                                question: 'What kind of tables do you offer?',
                                answer: 'We primarily offer rare wood slab dining tables that often incorporate other materials such as glass and resin. Our assortment includes: Rare single slabs, which have the most limited availability as they are a single board milled from an extraordinarily huge log; Premium Book Matched Slabs, which are two sequential boards from the same log that create a symmetrical effect in the grain; Divergence Series Slabs, these are created to your exact dimensions from hardwood boards, just not from the same log; Epoxy Resin Slab Tops combine resin & wood to create a truly unique custom result every time. Additionally, you have the option of glass on top of a rare slab or simply on a base alone. For any of these, you can choose to have a natural, straight finished or bull nose edge - or any overall table top shape your heart desires!',
                            },
                            {
                                question: 'What are common style preferences?',
                                answer: 'Our most popular dining table is a live edge book match Black Walnut slab, paired with our Jeffrey Greene sculpted T Base. As recent home decor styles have favored more neutral, calm, monochromatic palettes, lighter-colored species like White Oak, Ash, and Maple are becoming an increasingly hot trend. As over 50 years of work have proven, live edge tables are classic and timeless; they unquestionably work with any aesthetic from ultra-contemporary to traditionally restored homes from the 1800s.',
                            },
                            {
                                question: 'What customizations do we offer for our tables?',
                                answer: 'Some of the most common additional features we offer include - wood, metal, glass, and resin inlays; installation of tech grommets with concealed wire management; engraving & etching; custom stain colors; eccentric table top shapes; elevator friendly solutions; leaves or extension tables.',
                            },
                            {
                                question: 'I live in an apartment, do you have elevator friendly options?',
                                answer: 'Yes! After several requests from our design firm partners in NYC, we added two-piece table designs as a standard option due to the ease of delivery in any situation. This is perfect for smaller elevators, stair carries, homes with tight turns, or any circumstance that needs an adaptable design. Additionally, this feature allows the table to be easily relocated if needed. Please note that we require you to confirm access is possible regardless of the table size, design, or location parameters. ',
                            }
                        ]
                    } />

                    <h2>Species</h2>
                    <Accordion data={
                        [
                            {
                                question: 'What are the most popular wood species?',
                                answer: 'We are very fortunate to be located in a region where some of the most globally prized wood species such as Black Walnut, Maple, and Cherry, are found in abundance. These species are always in demand, as well as more currently trending species like White Oak and Ash. ',
                            },
                            {
                                question: 'What wood species makes sense for me?',
                                answer: 'Choosing a wood species for your piece can be an overwhelming decision, especially with the range of options we offer. Here are some questions you should ask yourself when making this decision: What color pallete do you envision? What is your budget? Are you looking for a very organic & vibrant grain pattern, or something more understated? Is this for indoor or outdoor use?',
                            },
                            {
                                question: 'Do you work with exotic wood species?',
                                answer: 'Yes, we work with exotic species from around the world! We source exclusively from a select group of mills to ensure all are ethically obtained and properly prepared.',
                            },
                            {
                                question: "Are there any wood species you don't work with?",
                                answer: 'We do not work with sappy woods such as pine, nor materials that do not pass our quality checks. This also applies to our locally offered shop services.',
                            }
                        ]
                    } />

                    <h2>Sizing</h2>
                    <Accordion data={
                        [
                            {
                                question: "What size is best for me?",
                                answer: "The size depends primarily on what your space can accommodate and how many you wish to seat. The rule of thumb is to allow a minimum of 3' of space around the table until the nearest obstacle, as this ensures that even with guests seated at the table, others may pass behind them. As for the number of guests, about 24\" to 30\" is suggested per person. For example, a 9' long table can comfortably seat ten - four on either side and one at each end.",
                            },
                            {
                                question: "How tall is a typical dining table or coffee table?",
                                answer: "The typical height of a Dining Table is 30\" overall, with approximately 28\" of space underneath for chairs. Coffee tables and benches have an average height of 17\" overall but can be adapted as needed.",
                            }
                        ]
                    } />

                    <h2>Bases</h2>
                    <Accordion data={
                        [
                            {
                                question: "Which base is best for me?",
                                answer: "You can think of this decision in three ways: Material—such as wood, metal, or plexiglass; Style—transitional, modern, geometric, soft curves; Legroom—smaller slabs may need a base that allows for maximum legroom; and Stability—this is our main concern, and we are happy to make suggestions based on your slab selection and aesthetic preferences. (link base page here)",
                            },
                            {
                                question: "What types of materials do you use for your bases?",
                                answer: "Most of our bases are created from wood or metal - both steel and solid cast aluminum. We also offer plexiglass on particular designs! ",
                            },
                            {
                                question: "What customizations do you offer?",
                                answer: "We can custom size virtually any design we showcase that is not made from a pre-cast mold. Providing inspiration images and size requirments are a great first step for custom sizing & design. Please keep in mind that we do not copy the work of other designers. You may certainly commission a completely custom designed base for an additional design fee. This fee is deducted from your final invoice once the order is placed. (custom base submission form)",
                            },
                            {
                                question: "What are Jeffery Greene Signature Designs?",
                                answer: "Jeffrey Greene is renowned for his exclusive base designs, many of which are copyrighted and truly original. These include the Forest Base, the Monolith, the Steel Fountain, the Tai Chi, and others noted as such. Limited Edition numbered designs include the Twisted Trapezoid and the Diminishing Ellipse. (link to base page)",
                            }, {
                                question: "How are your bases finished?",
                                answer: "For wood bases, we typically use a durable monocoat finish. This ensures long lasting protection, while still maintaining a natural look & feel. For steel & aluminum bases, we work with a local performance powder coating shop to finish in virtually any color you choose.",
                            },
                            {
                                question: "How do your bases attach?",
                                answer: "Specific care is given in the initial design of the bases so that they are stable and allow for seasonal movement of the wood slab top. Typically, we mechanically connect the base to the top using threaded inserts for ease of assembly with only an Allen key.",
                            }
                        ]
                    } />

                    <h2>Species</h2>
                    <Accordion data={
                        [
                            {
                                question: "What product do you use when finishing tables & furniture?",
                                answer: "Our preferred finish for tables & furniture is Rubio Monocoat. This is a 100% plant based, zero VOC, hardwax wood oil formulated to add slight UV protection, water resistantance, and provide a beautiful luster to any wood species. It is easy to care for and in the event of damage - it's easy to repair. Other types of creations such as exterior doors, custom cabinets, etc., will be finished with the product best suited to its use.  ",
                            },
                            {
                                question: "Do you offer any extra durable coatings?",
                                answer: "We offer a wood Graphene Ceramic Coating similar to what you would apply to a luxury vehicle. This coating comes in a Gold and Diamond level, and is scientifically formulated to penetrate microscopic pores and provide a bonded layer of protection. This finish aids in preventing and minimizing abrasions, staining, UV damage, and creates a hydrophobic finish causing liquids to bead on the surface. This product is considered the industry standard in wood finishes. ",
                            },
                            {
                                question: "Do you offer food-safe finishes?",
                                answer: "We use a 100% Plant Based Walrus Oil finish on our Home Accessory line. It is completely food safe!",
                            },
                            {
                                question: "Do you offer staining treatments?",
                                answer: "While our preferred finish applies clear to accentuate the natural beauty of every wood species, we do offer pallette color options that are easy to reproduce from our showroom samples. Additionally, we can match virtually any color swatch if the wood species is one that accepts color treatments. This can vary depending on the type of furniture and its intended use. Common stain selections include darkened, bleached, white washed, rustic/aged, and ebonized. We cannot stress enough that our samples may not represent the exact color of your chosen slab/wood species since the grain, pattern, and shade are unique to each wood slab.",
                            },
                        ]
                    } />
                </div>
            </Animate>


        </div>
    )
}

export default FAQs