import { configureStore } from '@reduxjs/toolkit';
import headerReducer from './slices/header-slice'
import appReducer from './slices/app-slice'


export default configureStore({
    reducer: {
        header: headerReducer,
        app: appReducer,
      },
})